import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle,
  DialogFooter,
  DialogDescription
} from './ui/dialog';
import { Button } from './ui/button';
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from './ui/select';
import { Label } from './ui/label';
// import { Switch } from './ui/switch';
import { Loader2, History } from 'lucide-react';
import api from '../services/api';
import { toast } from 'react-toastify';

export function ImportCorrectAnswersModal({ 
  open, 
  onOpenChange, 
  targetAssignmentId, 
  onImportPreview
}) {
  const [assignments, setAssignments] = useState([]);
  const [selectedAssignmentId, setSelectedAssignmentId] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  // We only import correct answers

  useEffect(() => {
    if (open) {
      fetchAssignments();
    }
  }, [open]);

  const fetchAssignments = async () => {
    setLoading(true);
    try {
      const data = await api.getAssignments();
      // Filter out the current assignment and sort by creation date
      const filteredAssignments = data
        .filter(a => a.id !== parseInt(targetAssignmentId))
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      
      setAssignments(filteredAssignments);
      
      // Preselect the first assignment if available
      if (filteredAssignments.length > 0) {
        setSelectedAssignmentId(filteredAssignments[0].id.toString());
      }
    } catch (error) {
      toast.error("Erreur lors du chargement des devoirs");
      console.error("Error fetching assignments:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleImport = async () => {
    if (!selectedAssignmentId) {
      toast.error("Veuillez sélectionner un devoir source");
      return;
    }

    setSubmitting(true);
    try {
      // Call the API - it now returns potential updates instead of making direct changes
      const result = await api.importCorrectAnswers(
        parseInt(selectedAssignmentId), 
        parseInt(targetAssignmentId)
      );
      
      // Pass the potential updates back to the parent page
      if (onImportPreview) {
        onImportPreview(result.potential_updates || []); // Pass the array of updates
      }
      
      // Show the message from the backend
      toast.info(result.message || `Prévisualisation de l'importation terminée.`);
      
      onOpenChange(false);
    } catch (error) {
      toast.error(error.message || "Erreur lors de la prévisualisation de l'importation");
      console.error("Error previewing correct answers import:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Importer les réponses correctes</DialogTitle>
          <DialogDescription>
            Sélectionnez un devoir source pour importer ses réponses correctes. Vous pourrez vérifier les modifications avant de les enregistrer.
          </DialogDescription>
        </DialogHeader>
        
        <div className="py-4">
          <Label htmlFor="source-assignment" className="block mb-2">
            Devoir source
          </Label>
          
          {loading ? (
            <div className="flex items-center justify-center p-4 text-sm text-gray-500">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Chargement des devoirs...
            </div>
          ) : assignments.length === 0 ? (
            <div className="text-sm text-gray-500 p-2 border rounded-md bg-muted/10">
              Aucun autre devoir disponible pour l'importation.
            </div>
          ) : (
            <Select
              value={selectedAssignmentId}
              onValueChange={setSelectedAssignmentId}
              disabled={submitting}
            >
              <SelectTrigger id="source-assignment" className="w-full">
                <SelectValue placeholder="Sélectionner un devoir source" />
              </SelectTrigger>
              <SelectContent style={{ backgroundColor: "white" }} className="!bg-white border-2">
                {assignments.map((assignment) => (
                  <SelectItem 
                    key={assignment.id} 
                    value={assignment.id.toString()} 
                    className="hover:bg-gray-100"
                    style={{ backgroundColor: "white" }}
                  >
                    {assignment.filename}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
          
          <p className="mt-3 text-sm text-muted-foreground">
            <History className="inline h-4 w-4 mr-1" />
            Seules les réponses pour des questions identiques seront proposées pour importation.
          </p>
        </div>
        
        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => onOpenChange(false)}
            disabled={submitting}
          >
            Annuler
          </Button>
          <Button 
            onClick={handleImport}
            disabled={!selectedAssignmentId || submitting || assignments.length === 0}
          >
            {submitting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Prévisualisation...
              </>
            ) : (
              "Prévisualiser les réponses"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default ImportCorrectAnswersModal;