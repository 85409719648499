/*
<ai_context>
GoogleFormGradingModal.jsx
This modal is for grading an assignment that was already created from a Google Form.
We rely on assignment.google_course_id and assignment.google_coursework_id to fetch the submissions.

Now that we do background tasks, we can:
 - still show "Correction en cours..." while the request is posted.
 - as soon as the request returns { message: "La correction est en cours" }, we close the modal and toast.
</ai_context>
*/

import React, { useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { Button } from "./ui/button";
import Spinner from "./Spinner";
import { toast } from "react-toastify";
import api from "../services/api";
import { API_BASE_URL, API_VERSION } from "../services/config";
import { Tabs, TabsList, TabsTrigger } from "./ui/tabs";

/**
 * Props:
 * - assignment: { id, google_course_id, google_coursework_id }
 * - onClose: function
 * - onDone: function
 */
function GoogleFormGradingModal({ assignment, onClose, onDone }) {
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const [submissions, setSubmissions] = useState([]);
  const [existingSubmissions, setExistingSubmissions] = useState([]);
  const [checkedEmails, setCheckedEmails] = useState([]);
  const [filterMode, setFilterMode] = useState("new"); // "new" or "all"

  const [loadingSubmissions, setLoadingSubmissions] = useState(false);
  const [loadingExistingSubmissions, setLoadingExistingSubmissions] = useState(false);
  const [grading, setGrading] = useState(false);
  const [error, setError] = useState("");

  const popupRef = useRef(null);
  const popupCheckIntervalRef = useRef(null);

  const courseId = assignment.google_course_id;
  const courseworkId = assignment.google_coursework_id;

  const ALLOWED_ORIGINS = [
    "http://localhost:3000",
    "http://127.0.0.1:3000",
    "http://localhost:5173",
    "http://127.0.0.1:5173",
    "http://localhost:8000",
    "http://127.0.0.1:8000",
    "https://api.corrige.ca",
    "https://corrige.ca",
    "https://app.corrige.ca",
  ];

  useEffect(() => {
    checkAuthorization();
  }, []);
  
  // Fetch existing submissions for this assignment to check which students are already imported
  async function fetchExistingSubmissions() {
    if (!assignment?.id) return;
    
    setLoadingExistingSubmissions(true);
    try {
      const response = await api.getSubmissionsByAssignment(assignment.id);
      setExistingSubmissions(response || []);
    } catch (err) {
      console.error("Error fetching existing submissions:", err);
    } finally {
      setLoadingExistingSubmissions(false);
    }
  }

  useEffect(() => {
    if (popupRef.current) {
      popupCheckIntervalRef.current = setInterval(() => {
        if (popupRef.current && popupRef.current.closed) {
          clearInterval(popupCheckIntervalRef.current);
          popupCheckIntervalRef.current = null;
          popupRef.current = null;
          if (!isAuthorized) {
            setIsAuthorizing(false);
          }
        }
      }, 800);
    }
    return () => {
      if (popupCheckIntervalRef.current) {
        clearInterval(popupCheckIntervalRef.current);
      }
    };
  }, [isAuthorized]);

  async function checkAuthorization() {
    const alreadyAuthorized = localStorage.getItem("googleAuthorized") === "true";
    if (alreadyAuthorized) {
      setIsAuthorized(true);
      loadSubmissions();
      return;
    }
    try {
      const me = await api.getCurrentUser();
      if (me.google_access_token) {
        setIsAuthorized(true);
        localStorage.setItem("googleAuthorized", "true");
        loadSubmissions();
      } else {
        openAuthPopup();
      }
    } catch (err) {
      console.error("Erreur lors de la vérification de l'autorisation Google:", err);
      setError(err.message);
    }
  }

  function openAuthPopup() {
    if (isAuthorizing) return;
    setIsAuthorizing(true);
    setError(""); // Réinitialiser les erreurs lors de la tentative de réauthentification

    api
      .getGoogleClassroomConnectUrl()
      .then((data) => {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Aucun jeton d'authentification local trouvé. Veuillez vous reconnecter.");
        }
        const urlWithState = `${data.auth_url}&state=${encodeURIComponent(token)}`;
        popupRef.current = window.open(
          urlWithState,
          "_blank",
          "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600"
        );
        window.addEventListener("message", handleMessage);
      })
      .catch((err) => {
        console.error("Erreur lors de l'ouverture de la fenêtre d'authentification:", err);
        setError(err.message);
        setIsAuthorizing(false);
      });
  }

  function handleMessage(event) {
    if (!ALLOWED_ORIGINS.includes(event.origin)) {
      return;
    }
    if (event.data === "google-oauth-success") {
      setIsAuthorized(true);
      setIsAuthorizing(false);
      localStorage.setItem("googleAuthorized", "true");
      if (popupRef.current) {
        popupRef.current.close();
      }
      popupRef.current = null;
      window.removeEventListener("message", handleMessage);
      // Réessayer de charger les soumissions après réauthentification
      loadSubmissions();
    }
  }

  async function loadSubmissions() {
    if (!courseId || !courseworkId) {
      setError("Aucun cours ou devoir Google n'est configuré pour ce devoir.");
      return;
    }
    setLoadingSubmissions(true);
    setError("");
    try {
      // Fetch Google Classroom submissions
      const data = await api.listSubmissions(courseId, courseworkId);
      // Afficher les soumissions TURNED_IN, RETURNED ou CREATED
      const turnedIn = data.filter((s) => s.state === "TURNED_IN" || s.state === "RETURNED" || s.state === "CREATED" || s.state === "FORM_ONLY" || s.state === "DEMO_LOADED");
      setSubmissions(turnedIn);
      
      // Also fetch existing submissions to filter out already imported students
      await fetchExistingSubmissions();
    } catch (err) {
      // Vérifier si l'erreur est liée à une expiration du jeton Google via le code de statut
      if (err.status === 401 && err.url && err.url.includes("/classroom/")) {
        // Réinitialiser l'état d'autorisation
        setIsAuthorized(false);
        localStorage.removeItem("googleAuthorized");
        // Déclencher la réauthentification
        openAuthPopup();
      } else {
        setError(err.message || "Erreur inconnue lors du chargement des soumissions.");
      }
    } finally {
      setLoadingSubmissions(false);
    }
  }

  function toggleCheckEmail(email) {
    setCheckedEmails((prev) => {
      if (prev.includes(email)) {
        return prev.filter((em) => em !== email);
      } else {
        return [...prev, email];
      }
    });
  }

  function toggleCheckAll() {
    const displayedSubmissions = getFilteredSubmissions();
    const allEmails = displayedSubmissions
      .map((sub) => sub.studentEmail)
      .filter((em) => !!em);
    if (checkedEmails.length === allEmails.length) {
      setCheckedEmails([]);
    } else {
      setCheckedEmails(allEmails);
    }
  }
  
  // Filter submissions based on whether they've already been imported
  function getFilteredSubmissions() {
    if (filterMode === "all") {
      return submissions;
    }
    
    // Get emails of students who already have submissions
    const existingEmails = existingSubmissions.map(sub => sub.student_email.toLowerCase());
    
    // Filter out students whose email is already in existingSubmissions
    return submissions.filter(sub => {
      const email = sub.studentEmail?.toLowerCase();
      return email && !existingEmails.includes(email);
    });
  }

  async function handleGrade() {
    if (!assignment?.id) {
      toast.error("Aucun devoir local trouvé.");
      return;
    }
    if (!checkedEmails.length) {
      toast.error("Veuillez sélectionner au moins un étudiant");
      return;
    }

    setGrading(true);
    setError("");

    try {
      const url = `${API_BASE_URL}${API_VERSION}/classroom/courses/${courseId}/assignments/${courseworkId}/form-submissions/grade`;
      const resp = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          assignment_id: assignment.id,
          student_emails: checkedEmails,
        }),
      });
      const txt = await resp.text();
      if (!resp.ok) {
        throw new Error(txt || "Échec de la correction depuis Google Form.");
      }
      const data = JSON.parse(txt);

      // Maintenant, on reçoit la réponse rapidement, signifiant que la correction s'exécute en arrière-plan.
      toast.success(data.message || "La correction a été déclenchée !");
      setGrading(false);

      // Fermer la modale
      onDone();
    } catch (err) {
      console.error("Erreur lors de la correction des soumissions Google Form:", err);
      // Vérifier si l'erreur est liée à une expiration du jeton Google
      if ((err.message.includes("401") || err.message.includes("Unauthorized")) && err.message.includes("Google")) {
        setIsAuthorized(false);
        localStorage.removeItem("googleAuthorized");
        openAuthPopup();
      } else {
        toast.error(err.message || "Échec de la correction depuis Google Form.");
        setError(err.message);
      }
      setGrading(false);
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="bg-white w-full max-w-4xl p-4 rounded shadow-md relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-sm text-gray-600 hover:text-gray-900"
        >
          X
        </button>

        {error && (
          <div className="mb-2 bg-destructive/20 text-destructive p-2 rounded text-sm">
            {error}
          </div>
        )}

        {!isAuthorized && isAuthorizing && (
          <div className="py-4 flex items-center justify-center">
            <Spinner />
            <span className="ml-2 text-sm">Veuillez réauthentifier votre compte Google...</span>
          </div>
        )}

        {isAuthorized && (
          <Card>
            <CardHeader>
              <CardTitle>Corriger via Google Form</CardTitle>
            </CardHeader>
            <CardContent>
              {loadingSubmissions || loadingExistingSubmissions ? (
                <div className="flex items-center space-x-2">
                  <Spinner />
                  <span className="text-sm">Chargement des remises...</span>
                </div>
              ) : (
                <>
                  {!submissions.length && (
                    <p className="text-sm text-muted-foreground">
                      Aucune remise trouvée ou l'état est différent de TURNED_IN/RETURNED/CREATED.
                    </p>
                  )}
                  {!!submissions.length && (
                    <>
                      <Tabs defaultValue="new" onValueChange={setFilterMode} className="mb-4">
                        <TabsList>
                          <TabsTrigger value="new">Nouvelles remises</TabsTrigger>
                          <TabsTrigger value="all">Toutes les remises</TabsTrigger>
                        </TabsList>
                      </Tabs>
                      
                      {getFilteredSubmissions().length === 0 ? (
                        <p className="text-sm text-muted-foreground py-4">
                          {filterMode === "new" 
                            ? "Toutes les remises ont déjà été importées pour correction." 
                            : "Aucune remise trouvée."}
                        </p>
                      ) : (
                        <div className="overflow-y-auto max-h-64">
                          <table className="w-full text-sm">
                            <thead className="border-b bg-muted/20">
                              <tr>
                                <th className="py-2 w-6 text-center">
                                  <input
                                    type="checkbox"
                                    checked={
                                      checkedEmails.length ===
                                      getFilteredSubmissions().filter((s) => s.studentEmail).length
                                    }
                                    onChange={toggleCheckAll}
                                  />
                                </th>
                                <th>Nom</th>
                                <th>Email</th>
                                <th>État</th>
                              </tr>
                            </thead>
                            <tbody>
                              {getFilteredSubmissions().map((sub) => {
                                const email = sub.studentEmail;
                                const isChecked = checkedEmails.includes(email);
                                const isAlreadyImported = existingSubmissions.some(
                                  (existing) => existing.student_email.toLowerCase() === email?.toLowerCase()
                                );
                                
                                return (
                                  <tr 
                                    key={sub.id} 
                                    className={`border-b ${isAlreadyImported ? 'bg-muted/10' : ''}`}
                                  >
                                    <td className="text-center p-2">
                                      {email ? (
                                        <input
                                          type="checkbox"
                                          checked={isChecked}
                                          onChange={() => toggleCheckEmail(email)}
                                        />
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </td>
                                    <td className="p-2">{sub.studentName || "Inconnu"}</td>
                                    <td className="p-2">{email || "N/A"}</td>
                                    <td className="p-2">
                                      {isAlreadyImported ? (
                                        <span className="text-muted-foreground">Déjà importé</span>
                                      ) : (
                                        sub.state
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}

              {/* Bouton de correction */}
              {submissions.length > 0 && (
                <div className="mt-4 flex justify-end">
                  <Button
                    onClick={handleGrade}
                    disabled={grading || !checkedEmails.length}
                  >
                    {grading ? "Correction en cours..." : "Corriger la sélection"}
                  </Button>
                </div>
              )}
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
}

export default GoogleFormGradingModal;